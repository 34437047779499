<template>
<div>
  <div class="container-fluid">
    <PageTitle :noAdd="true" />
  
    <div 
      v-if="isList" 
      class="card"
    >
      <div class="card-body">
        <div class="row">
          <div class="col-md-3">
            <h5 class="card-title">{{pageTitle}} List</h5>
          </div>
          <div class="col-md-9">
            <div class="row justify-content-end">
              <div class="col-md-5 ml-auto mb-2">
                <form @submit.prevent="doFilter()">
                  <div class="form-group mb-0">
                    <div class="input-group">
                      <input 
                        v-model="filter.search"
                        type="text" 
                        class="form-control" 
                        placeholder="Search"
                      />
                      <div class="input-group-append">
                        <button class="btn btn-info" type="submit"><i class="fas fa-search"></i></button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
              <div class="col-12 col-sm-auto col-md-auto">
                <button class="btn btn-info btn-block" type="button" @click="doReset">Reset</button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="table-responsive">
        <table class="table product-overview table-bordered">
          <thead>
            <tr>
              <th>#</th>
              <th>Name</th>
              <th>Tipe</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            <tr v-if="data.data===false"><td colspan="99" class="text-center"><Loader class="mt10 mb10" /></td></tr>
            <tr v-for="(v,k) in data.data" :key="k">
              <td>{{(data.per_page*(data.current_page-1))+k+1}}</td>
              
              <td>{{ v.mt_title }}</td>
              <td>{{ v.mt_type }}</td>

              <td>
                <router-link 
                  v-if="moduleRole('Edit')"
                  :to="{name:$route.name, params:{pageSlug:v[idKey]}}"  
                  class="text-inverse icon_action act_icon" v-tooltip="'Edit'">
                  <i class="ti-marker-alt"></i>
                </router-link> 
              </td>
            </tr>
            <tr v-if="notFound"><td colspan="99" class="text-center"><h4>{{notFound}}</h4></td></tr>
          </tbody>
        </table>
      </div>
      <div class="card-footer">
        <div class="row">
          <div class="col-sm-6"></div>
          <div class="col-sm-6">
            <div class="pagination-flat float-right" v-if="data.data!==false&&data.data.length">
              <Pagination :data="data" :limit="2" @pagination-change-page="doPaginate">
                <span slot="prev-nav">Previous</span>
                <span slot="next-nav">Next</span>
              </Pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div 
      v-else 
      class="card"
    >
      <div class="card-body">
        <h5 class="card-title mb-3">{{isAdd?'Add':'Edit'}} {{pageTitle}}</h5>
        
        <ValidationObserver v-slot="{ handleSubmit }" ref="VForm">
          <form @submit.prevent="handleSubmit(doSubmitCRUD)">
            <div class="row">
              <div class="col-md-12">
                
                <div class="form-group">
                  <label class="control-label">
                    Title 
                    <span class="text-danger mr5">*</span>
                  </label>
                  <input 
                    v-model="row.mt_title"
                    type="text" 
                    class="form-control" 
                    placeholder="Title" 
                  /> 
                  <VValidate name="Title" v-model="row.mt_title" :rules="mrValidation.mt_title" />
                </div>

                <div class="form-group">
                  <label class="control-label">
                    Description 
                    <span class="text-danger mr5">*</span>
                  </label>
                  <ckeditor
                    class="editor"
                    :editor="editor"
                    v-model="row.mt_description"
                    :config="editorConfig"
                  ></ckeditor>
                  <VValidate name="Description" v-model="row.mt_description" rules="required|editor" />
                </div>

              </div>
            </div>
            <div class="row mt-4">
              <div class="col-md-12 text-right">
                <router-link :to="{ name: $route.name }" class="btn btn-rounded btn-light mr-2">Cancel</router-link>
                <button type="submit" class="btn btn-rounded btn-info">{{isAdd ? "Submit" : "Update"}}</button>
              </div>
            </div>
          </form>
        </ValidationObserver>

      </div>
    </div>

  </div>
</div>
</template>

<script>
import GlobalVue from '@libs/Global'
import PageTitle from '@view/include/PageTitle'
import CKEditor from "@ckeditor/ckeditor5-vue2";
import ClassicEditor from "@libs/ckeditor5-custom-build/build/ckeditor";
import { extend } from "vee-validate";

export default{
  extends:GlobalVue,
  components:{PageTitle, ckeditor: CKEditor.component,},
  data(){
    return {
      idKey:'mt_id',
      editor: ClassicEditor,
      editorConfig: {
        ui: {
          viewportOffset: {
            top: 65,
          },
        },
        toolbar: {
          items: ["heading", "|", "bold", "fontColor", "link"],
        },
      },
    }
  },
  mounted(){
    this.apiGet()

    extend("editor", {
      message: "The {_field_} field must be at least 10 characters",
      validate: (value) => {
        try {
          return this.sanitize(value).length >= 10
        } catch (error) {
          return error
        }
      },
    });
  },
  watch:{
    $route(){
      this.apiGet()
    }
  }
}
</script>